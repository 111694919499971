<template>
  <div id="app">
    <router-view/>
    <Toaster :dataValue="toasterData" />
  </div>
</template>

<script>
import Toaster from "./components/Toaster";
import { SessionHelper , LANG} from './utils/session'
export default {
  name: 'App',
  components: {
    Toaster
  },
  data() {
    return {
      toasterData: {},
    }
  },
  mounted(){
    this.$i18n.locale = SessionHelper.getCookie(LANG) || "hi";
      window.addEventListener('online', (e) => {
        this.$store.commit('Internet',true)
        this.toasterData = {
            status: true,
            msg: "Online",
            timeoutMs: "5000",
            color: "#fff",
        };
    });
    window.addEventListener('offline', (e) => { 
        this.$store.commit('Internet',false)
        this.toasterData = {
            status: true,
            msg: "Offline",
            timeoutMs: "5000",
            color: "#fff",
          };
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
