<template>
  <div class="">
    <section class="home">
      <div class="cam-img">
        <video :class="'flash infinite faster'" autoplay></video>
      </div>
      <div class="home-content">
        <ul class="top-tab">
          <li :class="trendingRoute ? 'trending' : 'trending active' ">{{ $t("selfi") }}</li>
          <!--<li :class="trendingRoute ? 'trending active' : 'trending' " @click="handleRedirect">{{ $t("trending") }}</li>-->
        </ul>
        <div class="bottom-block">
          <div class="control-tools">
            <span>
              <!-- <img :src="require('../assets/images/thunder.svg')" alt=""/> -->
            </span>
            <div
              :class="$store.state.netOnOff ? btnAnimate ? 'make-img-btn a' : 'make-img-btn' : 'disable make-img-btn'"
              id="snap"
              @click="take"
            >
              <svg xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="23"
                  cy="23"
                  r="23"
                  :class="btnAnimate ? 'circle a' : 'circle'"
                ></circle>
              </svg>
            </div>
            <span @click="handleSwitchCamera"
              ><img :src="require('../assets/images/refresh.svg')" alt=""
            /></span>
          </div>
          <div class="filter-camera">
            <ul id="ulScroll">
              <li
                :class="activeIndex === index ? 'active' : ''"
                v-for="(filter, index) in FilterList"
                @click="handleSelecteFilterFromList(filter, index)"
              >
                <span>{{ $t(filter.name) }}</span>
              </li>
            </ul>
          </div>
          <div class="navbar">
            <span
              :class="!activeIndex ? 'left-click disable' : 'left-click'"
              @click="handleClickLeft"
            >
              <img src="../assets/images/next.png" alt="" />
            </span>
            <span
              :class="activeIndex > 7 ? 'right-click disable' : 'right-click'"
              @click="handleClickRight"
            >
              <img src="../assets/images/next.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    </section>
    <section
      :class="
        newPicturePopUp ? 'new-picture-popup active' : 'new-picture-popup'
      "
    >
      <div class="popup-content">
        <div class="popup-header">
          <span class="close-popup" @click="handleClosenewPicturePopUp"
            ><img :src="require('../assets/images/ic_close_24px.svg')" alt=""
          /></span>
          <span
            :class="downloadDisable ? 'download disable' : 'download'"
            @click="downloadMe"
            ><img :src="require('../assets/images/square-download.svg')" alt=""
          /></span>
          <span :class="gifId ? 'delete' : 'delete disable'" @click="GifDelete"><img :src="require('../assets/images/ic_delete_24px.svg')" alt=""></span>
        </div>
        <div class="click-images">
          <img v-if="recentImage" :src="recentImage" alt="" />
        </div>
        <span
          :class="walldisable ? 'save-btn disable' : 'save-btn'"
          @click="downloadMe"
          >{{ $t("share") }} <img :src="require('../assets/images/right_arrow.svg')" alt=""
        /></span>
      </div>
    </section>
    <div :class="loading ? 'loader' : 'loader hide'" id="loader"></div>
    <Toaster :dataValue="toasterData" />
  </div>
</template>

<script>
import Toaster from "../components/Toaster";
import { b64toBlob, permissionForCall } from "../utils/helper";
import { FILE_UPLOAD_URL } from "../utils/constant";
import VueCookie from "vue-cookies";
export default {
  name: "Home2",
  components: {
    Toaster,
  },
  props: {},
  watch: {},
  data() {
    return {
      SwiperActiveClassElement: "",
      trendingRoute: false,
      btnAnimate: false,
      newPicturePopUp: false,
      capturedImage: null,
      List: [],
      front: true,
      video: null,
      stream: null,
      recentImage: null,
      loading: false,
      openGallery: false,
      toasterData: {},
      disable: false,
      selectedfilter: "none",
      activeIndex: 0,
      walldisable: false,
      downloadDisable: false,
      cameraStreamCstm: null,
      gifId: null,
      tracks: [],
      select: null,
      counter: 0,
      deviceId: [],
      selected: undefined,
      showTrending: false,
      FilterList: [
        { name: "normal", filterCss: "none" },
        { name: "grayScale", filterCss: "grayscale(100%)" },
        { name: "contrast", filterCss: "contrast(100%)" },
        { name: "sepiaDark", filterCss: "sepia(100%)" },
        { name: "sepiaLight", filterCss: "sepia(50%)" },
        { name: "blur", filterCss: "blur(2px)" },
        { name: "saturateDark", filterCss: "saturate(100%)" },
        { name: "saturateLight", filterCss: "saturate(50%)" },
        { name: "invert", filterCss: "invert(100%)" },
      ],
    };
  },
  beforeDestroy(){
     if(this.cameraStreamCstm){
         this.cameraStreamCstm.getTracks().forEach(function(track) {
          track.stop();
        });
         this.cameraStreamCstm = null
         console.log('=in destroy=')
         this.video.remove()
         this.video = null
    }
  },
   mounted() {
    __native_money91.checkForPermissionResult = (result) => {
      if (result.length) {
        alert("Please Allow camera permission");
        JSBridgePlugin.gotToHome();
      } else {
         this.start();
      }
    };
    const _ = ["android.permission.CAMERA"];
    try {
      JSBridgePlugin.checkForPermission(
        _,
        true,
        "__native_money91.checkForPermissionResult"
      );
    } catch (err) {
      console.log(err);
       this.start();
    }
    this.video = document.querySelector("video");
  },
  methods: {
    gotDevices(mediaDevices) {
      this.deviceId = []
      mediaDevices.forEach(mediaDevice => {
        if (mediaDevice.kind === 'videoinput') {
          console.log('=mediaDevice.deviceId==',mediaDevice.deviceId)
          this.deviceId.push(mediaDevice.deviceId)
        }
      });
   } ,
    GifDelete(){
      this.$store.dispatch({type: 'GifDelete', payload : this.gifId}).then((data)=>{
        console.log('===',data.status)
          if(data.status){
          this.gifId = null
          this.toasterData = {
            status: true,
            msg: this.$t('postRemoved'),
            timeoutMs: "5000",
            color: "#fff",
          };
          }
      })
    },
    handleClosenewPicturePopUp() {
      this.newPicturePopUp = false;
    },
    handleRedirect() {
      this.trendingRoute = true
      this.$router.push("/trending");
    },
    makeWall() {
      if (this.capturedImage) {
        this.loading = true;
        this.walldisable = true;
        this.$store
          .dispatch({
            type: "MakeWall",
            payload: {
              url: this.capturedImage,
            },
          })
          .then((data) => {
            this.toasterData = {
              status: true,
              msg: this.$t('postTowall'),
              timeoutMs: "5000",
              color: "#fff",
            };
            this.loading = false;
          });
      }
    },
    handleClickRight() {
      document.getElementById("ulScroll").scrollBy(108, 0);
      if (this.activeIndex < this.FilterList.length - 1) {
        this.activeIndex++;
        this.selectedfilter = this.FilterList[this.activeIndex].filterCss;
        this.video.style.filter = this.FilterList[this.activeIndex].filterCss;
      }
    },
    handleClickLeft() {
      document.getElementById("ulScroll").scrollBy(-108, 0);
      if (this.activeIndex > 1) {
        this.activeIndex--;
      } else {
        this.activeIndex = 0;
      }
      this.selectedfilter =
        this.FilterList && this.FilterList[this.activeIndex].filterCss;
      this.video.style.filter =
        this.FilterList && this.FilterList[this.activeIndex].filterCss;
    },
    handleSave() {
      this.loading = true;
      this.disable = true;
      this.$store
        .dispatch({
          type: "GifUpload",
          payload: {
            url: this.capturedImage
          },
        })
        .then((data) => {
          console.log("==", data.data._id);
          this.gifId = data.data._id
          this.toasterData = {
            status: true,
            msg: this.$t('imageUpload'),
            timeoutMs: "5000",
            color: "#fff",
          };
          this.$forceUpdate();
          this.$store.commit("getGifList", data);
          this.loading = false;
        });
    },
    handleSelecteFilterFromList(filter, index) {
      console.log("==filter=", filter);
      this.activeIndex = index;
      this.selectedfilter = filter.filterCss;
      this.video.style.filter = filter.filterCss;
    },
    handleOpenGallery() {
      this.openGallery = true;
    },
    closeGallery() {
      console.log("==close--gallery");
      this.openGallery = false;
    },
    b64toBlob,
    permissionForCall,
    handleError(err) {
      console.log('err.name',err.name, {err});
      if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {
        var videoConstraint = {};
        if (this.selected === undefined) {
          videoConstraint.facingMode = 'user';
        } else {
          videoConstraint.deviceId = { exact: this.selected };
        }
        var constraint = {
          audio: false,
          video: videoConstraint,
        };
        navigator.mediaDevices
          .getUserMedia(constraint)
          .then(this.handleSuccess)
          .then(this.gotDevices)
          .catch(this.handleError);
        }
    },
    handleSuccess(stream) {
      if(stream){
        this.cameraStreamCstm = stream;
        if ((this.deviceId && this.deviceId[0] === this.selected)|| (this.selected === undefined)) {
          this.video.style.transform = "scaleX(-1)";
        } else {
          this.video.style.transform = "scaleX(1)";
        }
        this.video.srcObject = stream;
        return navigator.mediaDevices.enumerateDevices();
      }
    },
    handleSwitchCamera() {
      this.counter++
      if(this.counter === this.deviceId.length)
         this.counter = 0
      console.log('=countercountercounter=', this.counter)
      console.log('=this.deviceId=this.deviceId=',this.deviceId)
      this.selected = this.deviceId &&  this.deviceId[this.counter]
      if(this.cameraStreamCstm){
         this.cameraStreamCstm.getTracks().forEach(function(track) {
          track.stop();
        });
        this.cameraStreamCstm = null
        this.video.srcObject = null;
      }
      this.start();
    },
    async start() {
      var videoConstraints = {};
      if (this.selected === undefined) {
        videoConstraints.facingMode = 'user';
      } else {
        videoConstraints.deviceId = { exact: this.selected };
      }
      var constraints = {
        audio: false,
        video: videoConstraints,
      };
      console.log('==this.selected=', this.selected)
      console.log('==constraints=',{constraints})
      try{
         await navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.handleSuccess)
        .then(this.gotDevices)
      }catch(err){
        this.handleError(err)
      }

    },
    take() {
      this.capturedImage = null;
      this.gifId = null
      this.disable = false;
      this.walldisable = true,
      this.downloadDisable = true;
      this.recentImage = null;
      this.btnAnimate = true;
      gifshot.createGIF(
        {
          gifHeight: 768,
          gifWidth: 456,
          filter: this.selectedfilter,
          interval: 0.1,
          numFrames: 18,
          frameDuration: 1,
          fontWeight: "normal",
          fontSize: "16px",
          fontFamily: "sans-serif",
          fontColor: "#ffffff",
          textAlign: "right",
          textBaseline: "bottom",
          sampleInterval: 10,
          numWorkers: 5,
          keepCameraOn: true,
          cameraStream: this.cameraStreamCstm,
          saveRenderingContexts: true,
          savedRenderingContexts: [],
          text: " $ROVI ",
          progressCallback: (progress) => {
            if (
              !document
                .querySelector("video")
                .classList.hasOwnProperty("animated")
            ) {
              document.querySelector("video").classList.add("animated");
            }
            if (progress == 1) {
              document.querySelector("video").classList.remove("animated");
              this.loading = true;
            }
          },
        },
        (obj) => {
          if (!obj.error) {
            console.log("==saveRenderingContexts==", obj);
            this.cameraStreamCstm = obj.cameraStream;
            var image = obj.image;
            console.log("===image image=", image);
            this.loading = false;
            this.btnAnimate = false;
            this.newPicturePopUp = true;
            this.uploadFile(image);
            this.recentImage = image;
          }
          this.loading = false;
        }
      );
    },
    downloadMe() {
      this.downloadDisable = true;
      try {
        JSBridgePlugin.downloadVideoAndShare(this.capturedImage, "via mall91");
      } catch (e) {
        console.log(e);
      }
      if (!this.walldisable) {
        this.makeWall();
      }
    },
    uploadFile(fileData) {
      // window.document.getElementById("loader").classList.remove("hide");
      this.loading = false;
      var ImageURL = fileData;
      // Split the base64 string in data and contentType
      var block = ImageURL.split(";");
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      // Convert it to a blob to upload
      var blob = b64toBlob(realData, contentType);
      // Create a FormData and append the file with "image" as parameter name
      var formDataToUpload = new FormData();
      formDataToUpload.append("image", blob);
      formDataToUpload.append("isResizeRequired", true);
      fetch(FILE_UPLOAD_URL, {
        method: "POST",
        body: formDataToUpload,
      })
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          }
          // Examine the text in the response
          response.json().then((data) => {
            console.log(data.mediaVariantDTOs[0].url);
            this.capturedImage = data.mediaVariantDTOs[0].url;
            (this.walldisable = false), (this.downloadDisable = false);
            //this.handleSave();

            setTimeout(
              function(data) {
                window.document.getElementById("loader").classList.add("hide");
              },
              500,
              data
            );
          });
        })
        .catch(function(err) {
          window.document.getElementById("loader").classList.add("hide");
          console.log("Fetch Error :-S", err);
        });
    },
  },
};
</script>
