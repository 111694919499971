<template>
  <div class="toaster bounceInUp animated">
    <div id="snackbar" :style="{ background: color }" v-if="status">
      <span class="close_button" @click="status= false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19
            12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </span>
          <p>{{ msg }}</p>
        </div>
      </div>
    </template>
    <script>
export default {
  name: "Toaster",
  props: {
    dataValue: {
      type: Object
    },
  },
  data() {
    return {
      msg: "",
      color: "#fffff",
      status: false
    };
  },
  watch: {
    dataValue() {
      if (this.dataValue) {
        this.status = this.dataValue.status;
        this.msg = this.dataValue.msg;
        this.color = this.dataValue.color;
        setTimeout(
          () => {
            this.status = false;
          },
          this.dataValue.timeoutMs ? this.dataValue.timeoutMs : 500000
        );
      }
    }
  },
  mounted() {
    if (this.dataValue) {
      this.status = this.dataValue.status;
      this.msg = this.dataValue.msg;
      this.color = this.dataValue.color;
      setTimeout(
        () => {
          console.log('==chlaa==')
          this.status = false;
        },
        this.dataValue.timeoutMs ? this.dataValue.timeoutMs : 500000
      );
    }
  }
};
</script>
    <style scoped>
.toaster {
  width: 100%;
  transition: all ease-in 0.3s;
  position: fixed;
  left: 0px;
  top: 0;
  z-index: 999;
}
#snackbar {
  width: 100%;
  color: #000;
  text-align: center;
  padding: 10px 20px;
  font-size: 0.875em;
}
#snackbar .close_button {
/* background: #fff; */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
#snackbar .close_button svg {
  width: 16px;
  height: 16px;
  fill: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
