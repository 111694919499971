import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from "@/utils/i18n";
// import './assets/style.css'
import './assets/css/Style.scss'
// import './assets/css/Animation.css'
import './assets/css/SlideOption.css'
// import './assets/animation.css'
import './assets/script/gifshot.min'
import './assets/js/SlideOption.min.js'

Vue.config.productionTip = false
window.__native_money91 = {}
Vue.use(window.ads91);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
