import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

const messages = {
    hi: {
        trending: "Trending",
        selfi: "Selfie",
        normal: "Normal",
        grayScale: "Gray Scale",
        contrast: "Contrast",
        sepiaDark: "Sepia Dark",
        sepiaLight: "Sepia Light",
        blur: "Blur",
        saturateDark: "Saturate Dark",
        saturateLight: "Saturate Light",
        invert: "Invert",
        imageUpload: "Image Uploaded",
        postTowall: "Posted to wall",
        showmore: 'show more',
        noRecord: 'No records available',
        postRemoved: "post removed from trending list",
        download: "Download",
        share: "Share",
        enableInternet: "Please enable your internet"
    },
    en: {
        trending: "Trending",
        selfi: "Selfie",
        normal: "Normal",
        grayScale: "Gray Scale",
        contrast: "Contrast",
        sepiaDark: "Sepia Dark",
        sepiaLight: "Sepia Light",
        blur: "Blur",
        saturateDark: "Saturate Dark",
        saturateLight: "Saturate Light",
        invert: "Invert",
        imageUpload: "Image Uploaded",
        postTowall: "Posted to wall",
        showmore: 'show more',
        noRecord: 'No records available',
        postRemoved: "post removed from trending list",
        download: "Download",
        share: "Share",
        enableInternet: "Please enable your internet"
    },
    // hi: {
    //     trending: "ट्रैंडिंग",
    //     selfi: "सेल्फी",
    //     normal: "नोरमल",
    //     grayScale: "ग्रे स्केल",
    //     contrast: "कॉनट्रास्ट",
    //     sepiaDark: "सीपिया डार्क",
    //     sepiaLight: "सीपिया लाइट",
    //     blur: "ब्लर",
    //     saturateDark: "सेचुरेट डार्क",
    //     saturateLight: "सेचुरेट लाइट",
    //     invert: "इन्वर्ट",
    //     imageUpload: "छवि अपलोड की गई",
    //     postTowall: "पोस्ट वाल फीड पर अपलोड कर दी है",
    //     showmore: 'और देखें',
    //     noRecord: 'कोई रिकॉर्ड उपलब्ध नहीं है',
    //     postRemoved: "ट्रेंडिंग सूची से पोस्ट हटा दी गई",
    //     download: "डाउनलोड",
    //     share: "शेयर" ,
    //     enableInternet: "कृपया अपना इंटरनेट सक्षम करें"
    // },

    bn: {
        trending: "ট্রেন্ডিং",
        selfi: "সেলফি",
        normal: "নরমাল",
        grayScale: "গ্রে স্কেল",
        contrast: "কনট্রাস্ট ",
        sepiaDark: "সিপিএ ডার্ক",
        sepiaLight: "সিপিএ লাইট ",
        blur: "ব্লুর ",
        saturateDark: "স্ট্যাটুরেটেড ডার্ক ",
        saturateLight: "স্যাচুরেটেড লাইট ",
        invert: "ইনভার্ট ",
        imageUpload: "চিত্র আপলোড হয়েছে",
        postTowall: "পোস্টটি ওয়াল ফিডে আপলোড করা হয়েছে",
        showmore: 'আরো দেখুন',
        noRecord: 'কোন রেকর্ড উপলব্ধ',
        postRemoved: "ট্রেন্ডিং তালিকা থেকে পোস্ট সরানো হয়েছে",
        download: "ডাউনলোড",
        share: "ভাগ" ,
        enableInternet: "আপনার ইন্টারনেট সক্ষম করুন"

    },
    gu: {
        trending: "ટ્રેન્ડિંગ",
        selfi: "સેલ્ફી",
        normal: "નોર્મલ ",
        grayScale: "ગ્રે સ્કેલ",
        contrast: "કોન્ટ્રાસ્ટ",
        sepiaDark: "સેપિયા ડાર્ક ",
        sepiaLight: "સેપિયા લઈટ ",
        blur: "બ્લુરે",
        saturateDark: "સાટુંરેટેડ ડાર્ક",
        saturateLight: "સાટુંરેટેડ લઈટ",
        invert: "ઇન્વર્ટ ",
        imageUpload: "છબી અપલોડ થઈ",
        postTowall: "પોસ્ટ વ wallલ ફીડ પર અપલોડ કરવામાં આવી છે",
        showmore: 'વધારે બતાવ',
        noRecord: 'કોઈ રેકોર્ડ ઉપલબ્ધ નથી',
        postRemoved: "ટ્રેન્ડિંગ સૂચિમાંથી પોસ્ટ દૂર કરી",
        download: "ડાઉનલોડ કરો" ,
        share: "શેર કરો",
        enableInternet: "કૃપા કરીને તમારું ઇન્ટરનેટ સક્ષમ કરો"

    },

    kn: {
        trending: "ಟ್ರೆಂಡಿಂಗ್",
        selfi: "ಸೆಲ್ಫಿ ",
        normal: "ನಾರ್ಮಲ್ ",
        grayScale: "ಗ್ರೇ ಸ್ಕೇಲ್",
        contrast: "ಕಾಂಟ್ರಾಸ್ಟ್",
        sepiaDark: "ಸೆಪಿಯಾ ಡಾರ್ಕ್",
        sepiaLight: "ಸೆಪಿಯಾ ಲೈಟ್ ",
        blur: "ಬ್ಲರ್",
        saturateDark: "ಸತುರಟೆಡ್ ಡಾರ್ಕ್",
        saturateLight: "ಸತುರಟೆಡ್ ಲೈಟ್",
        invert: "ಇನ್ವರ್ಟ್",
        imageUpload: "ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
        postTowall: "ಪೋಸ್ಟ್ ಅನ್ನು ಗೋಡೆಯ ಫೀಡ್‌ಗೆ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
        showmore: 'ಇನ್ನೂ ಹೆಚ್ಚು ನೋಡು',
        noRecord: 'ಯಾವುದೇ ದಾಖಲೆಗಳು ಲಭ್ಯವಿಲ್ಲ',
        postRemoved: "ಧೋರಣೆ ಪಟ್ಟಿಯಿಂದ ತೆಗೆದುಹಾಕಲಾಗಿದೆ ಪೋಸ್ಟ್",
        download: "ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ" ,
        share: "ಪಾಲು",
        enableInternet: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಂಟರ್ನೆಟ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ"

    },
    ml: {
        trending: "ട്രെൻഡിങ്",
        selfi: "സെൽഫി",
        normal: "നോർമൽ",
        grayScale: "ഗ്രേയ്‌ സ്കെയിൽ",
        contrast: "കോൺട്രാസ്റ്",
        sepiaDark: "സെപ്പിയ ഡാർക്ക്",
        sepiaLight: "സെപ്പിയ ലൈറ്റ്",
        blur: "ബ്‌ളൂർ",
        saturateDark: "സാറ്റുറേറ്റഡ് ഡാർക്ക്",
        saturateLight: "സാറ്റുറേറ്റഡ് ലൈറ്റ്",
        invert: "ഇൻവെർട്",
        imageUpload: "ചിത്രം അപ്‌ലോഡുചെയ്‌തു",
        postTowall: "മതിൽ ഫീഡിലേക്ക് പോസ്റ്റ് അപ്‌ലോഡുചെയ്‌തു",
        showmore: 'കൂടുതൽ കാണുക',
        noRecord: 'റെക്കോർഡുകളൊന്നും ലഭ്യമല്ല',
        postRemoved: "ട്രെൻഡുചെയ്യുന്ന പട്ടികയിൽ നിന്ന് കുറിപ്പ് നീക്കംചെയ്‌തു",
        download: "ഡൗൺലോഡ്" ,
        share: "പങ്കിടുക",
        enableInternet: "നിങ്ങളുടെ ഇന്റർനെറ്റ് പ്രാപ്തമാക്കുക"
    },
    mr: {
        trending: "ट्रेंडिंग",
        selfi: "सेल्फी",
        normal: "नॉर्मल",
        grayScale: "ग्राय साचले",
        contrast: "कॉन्ट्रास्ट",
        sepiaDark: "सेपिया डार्क",
        sepiaLight: "सेपिया लीगत",
        blur: "ब्लुर",
        saturateDark: "स्टुर्टेड डार्क",
        saturateLight: "स्टुर्टेड लीगत ",
        invert: "इन्व्हर्ट",
        imageUpload: "प्रतिमा अपलोड केली",
        postTowall: "पोस्ट वॉल फीडवर अपलोड केली गेली आहे",
        showmore: 'अधिक पहा',
        noRecord: 'रेकॉर्ड उपलब्ध नाहीत',
        postRemoved: "ट्रेंडिंग सूचीमधून पोस्ट काढली",
        download: "डाउनलोड",
        share: "सामायिक करा" ,
        enableInternet: "कृपया आपले इंटरनेट सक्षम करा"

    },
    pa: {
        trending: "ਤਰੇਂਡਿੰਗ",
        selfi: "ਸੈਲਫੀ",
        normal: "ਨੌਰਮਲ",
        grayScale: "ਗਰੇ ਸਕੈਲੇ",
        contrast: "ਕੰਟਰਾਸਟ",
        sepiaDark: "ਸੇਪਿਆ ਡਾਰਕ",
        sepiaLight: "ਸੇਪਿਆ ਲਾਈਟ",
        blur: "ਬਲਰ",
        saturateDark: "ਸਤੁਰਾਟੇਡ ਡਾਰਕ",
        saturateLight: "ਸਤੁਰਾਟੇਡ ਲਾਈਟ ",
        invert: "ਇੰਤਤ",
        imageUpload: "ਤਸਵੀਰ ਅਪਲੋਡ ਕੀਤੀ ਗਈ",
        postTowall: "ਪੋਸਟ ਨੂੰ ਵਾਲ ਫੀਡ 'ਤੇ ਅਪਲੋਡ ਕੀਤਾ ਗਿਆ ਹੈ",
        showmore: 'ਹੋਰ ਦੇਖੋ',
        noRecord: 'ਕੋਈ ਰਿਕਾਰਡ ਉਪਲਬਧ ਨਹੀਂ ਹੈ',
        postRemoved: "ਟ੍ਰੈਂਡਿੰਗ ਲਿਸਟ ਤੋਂ ਪੋਸਟ ਹਟਾ ਦਿੱਤੀ ਗਈ",
        download: "ਡਾ .ਨਲੋਡ",
        share: "ਸ਼ੇਅਰ",
        enableInternet: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਇੰਟਰਨੈਟ ਨੂੰ ਸਮਰੱਥ ਕਰੋ"

    },
    ta: {
        trending: "ட்ரெண்டிங்",
        selfi: "செலஃபீ",
        normal: "நோர்மல்",
        grayScale: "கிரய ஸ்கேல்",
        contrast: "கான்ட்ராஸ்ட்",
        sepiaDark: "செபியா டார்க்",
        sepiaLight: "செபியா லைட்",
        blur: "பழூர்",
        saturateDark: "சதுரெட் டார்க்",
        saturateLight: "சதுரெட் லைட்",
        invert: "இன்வெர்ட்ட",
        imageUpload: "படம் பதிவேற்றப்பட்டது",
        postTowall: "இடுகை சுவர் ஊட்டத்தில் பதிவேற்றப்பட்டுள்ளது",
        showmore: 'மேலும் பார்க்க',
        noRecord: 'பதிவுகள் எதுவும் கிடைக்கவில்லை',
        postRemoved: "போக்கு பட்டியலிலிருந்து அகற்றப்பட்டது",
        download: "பதிவிறக்க Tamil",
        share: "பகிர்",
        enableInternet: "உங்கள் இணையத்தை இயக்கவும்"

    },
    te: {
        trending: "ట్రేండింగ్",
        selfi: "సెల్ఫీ",
        normal: "నార్మల్",
        grayScale: "గ్రీ స్కేల్",
        contrast: "కాంట్రాస్ట్",
        sepiaDark: "సిపిఐ డార్క్",
        sepiaLight: "సిపిఐ లైట్",
        blur: "బ్లర్",
        saturateDark: "సాతురేటెడ్ డార్క్",
        saturateLight: "సాతురేటెడ్ లైట్",
        invert: "ఇన్వెర్ట్",
        imageUpload: "చిత్రం అప్‌లోడ్ చేయబడింది",
        postTowall: "పోస్ట్ గోడ ఫీడ్‌లో అప్‌లోడ్ చేయబడింది",
        showmore: 'ఇంకా చూడుము',
        noRecord: 'రికార్డులు అందుబాటులో లేవు',
        postRemoved: "ట్రెండింగ్ జాబితా నుండి పోస్ట్ తొలగించబడింది",
        download: "డౌన్లోడ్",
        share: "వాటా",
        enableInternet: "దయచేసి మీ ఇంటర్నెట్‌ను ప్రారంభించండి"
    }
}


const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages // set locale messages
})

export default i18n
