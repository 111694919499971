<template>
 <div>
   <section class="trending" v-show="($store.state.netOnOff && !trendingPreview)">
    <header>
      <span class="back-icon" @click="handleBack"><img :src="require('../assets/images/arrow_back.svg')" alt=""></span>
      <span class="title">{{$t('trending')}}</span>
    </header>
    <div class="banner-ad">
      <ads-91 :unitIdentifier="BannerIdentifier" />
    </div>
    <div class="trending-imgs animated slideInUp">
      <div class="img" v-for="(item, index) in pagingList">
        <img  :data-src="item.url" :src="require('../assets/images/gray_image.jpg')" class="lozad" alt="" @click="handleOpenGif(item.url)">
        <div class="footer" >
          <span @click="handleLikegif(item, index)" :id="`like${index}`" :class="(item.isLikedByMe) ? 'active disablelike' : ''"></span> <small>{{item.likeCount}}</small>
        </div>
      </div>
      <div class="trending-imgs animated slideInUp">
      </div>
    </div>
   <!-- <ads-91 :unitIdentifier="LeadIdentifier" /> -->
    <div class="show_more">
      <span @click="handleShowMore"  v-show="showloadMore && (postCount > 10) && (pagingList.length < postCount)">{{$t('showmore')}}</span>
      <span v-if="showloadMore && !pagingList.length"> {{$t('noRecord')}} </span>
    </div>
  </section>
  <TrendingPreview v-if="$store.state.netOnOff && trendingPreview" @closePreview="closePreview" :selectedGif="selectedGif"/>
  <div v-show="!$store.state.netOnOff" class="netoff-section"> {{$t('enableInternet')}} </div>
  </div>
</template>
<script>
import TrendingPreview from './TrendingPreview'
import lozad from 'lozad'
const observer = lozad(); // lazy loads elements with default selector as '.lozad'

export default {
  name: "Trending",
  components:{
    TrendingPreview,
  },
  props:{
   List: Object,
   capturedImage: String
  },
  watch:{},
  data(){
      return{
          selectedGif: null,
          loader: false,
          pagingList: [],
          limit: 0,
          startLimit: 0,
          showloadMore: false,
          trendingPreview: false,
          likeCount: 0,
          selectedLikeIndex: null,
          postCount: 0,
          LeadIdentifier: '46ffa629-a94f-4c9b-b31e-dbc8fcfd8501',
          BannerIdentifier: 'cdf37d95-72a5-4658-8ee7-94f8345a8caf',
          // cdf37d95-72a5-4658-8ee7-94f8345a8caf new banner 
          // 46ffa629-a94f-4c9b-b31e-dbc8fcfd8501 new lead
          // b9a4ba21-16d4-4f68-ae12-68ed948ee1ad
          // fc6181e0-c29e-40e0-ab90-7f277e88f64b
      }
  },
  mounted(){
     this.$store
      .dispatch({ type: "getGifList" , payload:{offset: 0}})
      .then(data => {
        console.log('=pagingList=shsshhs new=',data.data)
        this.$nextTick().then(()=>{
          observer.observe();
        })
        this.pagingList = data.data.posts;
        this.postCount = data.data.count
        this.showloadMore = true
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods:{
    handleBack(){
        this.$router.go(-1)
    },
    handleLikegif(item, index){
      console.log('=item=',item)
      this.selectedLikeIndex = index
      document.getElementById(`like${index}`).classList.add("active" , "disablelike");
      this.$store.dispatch({type:'GifLike', payload: {postId: item._id}}).then((data)=>{
        console.log('===', data)
        if(data.status){
          if(this.selectedLikeIndex === index){
            item.likeCount++
          }
        }
      })
    },
    closePreview(){
        this.trendingPreview = false
          this.$nextTick().then(()=>{
          observer.observe();
        })
    },
    downloadMe(selectedGif) {
      try {
        JSBridgePlugin.downloadVideoAndShare(selectedGif, "via mall91");
      } catch (e) {
        console.log(e);
      }
    },
    handleOpenGif(item){
        this.loader = true
        this.trendingPreview = true
        this.selectedGif = item
        if(this.selectedGif){
            this.loader = false
        }
    },
    closeGallery(){
      let GalleyIimages = document.querySelector(".gallery");
      document.querySelector(".close").addEventListener("click", () => {
        GalleyIimages.style.top = "100%";
      });
      this.$emit('closeGallery')
    },
    handleShowMore(){
    this.loader = true
    this.limit = this.limit + 10
     this.$store
      .dispatch({ type: "getGifList" , payload:{offset: this.limit}})
      .then(data => {
        if(data.data.posts.length){
          this.pagingList = this.pagingList.concat(data.data.posts);
            console.log('==this.pagingList.length==',this.pagingList.length)
          this.postCount = data.data.count
        }
         this.$nextTick().then(()=>{
          observer.observe();
        })
        this.loader = false
      })
      .catch(err => {
        console.log(err);
      });
    },
    closePopUp(){
        this.selectedGif = null
    }  
  },
}
</script>