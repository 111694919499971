<template>
    <section class="trending-priview animated zoomIn faster">
        <span class="close-img" @click="closePreview"><img :src="require('../assets/images/ic_close_24px.svg')" alt=""></span>
        <div class="resent-image">
          <img  :src="selectedGif"  alt="">
        </div>
        <div class="bottom-content">
        <!-- <div class="names">
            <span class="brand">#Mall91</span>
            <span class="name">@anuman</span>
        </div> -->
        <div :class="downloadDisable ? 'download disable': 'download' " @click="downloadMe">
            <span class="do-icon"><img :src="require('../assets/images/square-download.svg')" alt=""></span>
            <span>{{$t('download')}}</span>
        </div>
        </div>
    </section>
</template>
<script>
import lozad from 'lozad'
const observer = lozad(); // lazy loads elements with default selector as '.lozad'

export default {
  name: "TrendingPreview",
  props:{
      selectedGif: String
  },
  data(){
    return{
      downloadDisable: false
    }
  },
  mounted(){
       this.$nextTick().then(()=>{
          observer.observe();
        })
  },
  methods:{
    closePreview(){
        this.$emit('closePreview')
    },
    downloadMe() {
      console.log('-ckkick')
      this.downloadDisable = true
       try {
        JSBridgePlugin.downloadVideoAndShare(this.selectedGif, "via mall91");
      } catch (e) {
        console.log(e);
      }
     
    },
  },
 
}
</script>