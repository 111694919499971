export const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((pos, err) => {
            if (pos) {
                return resolve(pos.coords)
            } else {
                return reject(err)
            }
        });
    })
}
export const getBase64Url = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var base64 = new Promise(function(resolve, reject) {
        reader.onload = (event) => {
            if (event.target)
                resolve(event.target.result);
            else
                reject(new Error('fail'))
        };
    });
    return base64;
}

export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (
      var offset = 0;
      offset < byteCharacters.length;
      offset += sliceSize
    ) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

export const toaster = (_this, msg, position, duration) => {
    _this.$toasted.show(msg, {
        theme: "toasted-primary",
        duration: duration,
        position: position,
        closeOnSwipe: false,
        action: {
            text: "Close",
            onClick: (e, toastObject) => {
                toastObject.goAway(0);
            }
        }
    });
};

export const permissionForCall = () => {
    // To allow JSbridge mic permission
    // "android.permission.RECORD_AUDIO",
    // "android.permission.WRITE_EXTERNAL_STORAGE",
    // "android.permission.READ_EXTERNAL_STORAGE"
    const _ = [
      "android.permission.CAMERA",
    ];
    try {
      JSBridgePlugin.checkForPermission(
        _,
        true,
        "__native_money91.checkForPermissionResult"
      );
    } catch (err) {
      console.log(err);
    }
  };