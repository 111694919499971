import Vue from 'vue'
import Vuex from 'vuex'
import ApiCaller from "../utils/apicaller";
import VueCookie from 'vue-cookies'
import { BASE_API_URL, BASE_API_URL2 } from '../utils/constant'

Vue.use(Vuex)
Vue.use(VueCookie)
export default new Vuex.Store({
  state: {
    giftList: [],
    netOnOff: true,
    selectedCam: undefined
  },
  mutations: {
    getGifList: (state, payload) => {
      state.giftList = state.payload;
   },
   'Internet': (state, payload) => {
     state.netOnOff = payload
    },
    'SelectedCam': (state, payload) => {
      state.selectedCam = payload
    }
  },
  actions: {
   GifUpload({ commit }, { type, payload }) {
      return ApiCaller(BASE_API_URL,'/post', "post", payload)
   },
   getGifList({ commit }, { type, payload }) {
    return ApiCaller(BASE_API_URL,`/post?offset=${payload.offset}`, "get")
   },
   MakeWall({ commit }, { type, payload }) {
     return ApiCaller(BASE_API_URL,'/gif/makeWall', "post", payload)
   },
   GifDelete({ commit }, { type, payload }) {
    return ApiCaller(BASE_API_URL,`/post/${payload}`, "delete")
  },
  GifLike({ commit }, { type, payload }) {
    console.log('=payloadpayloadpayload=',payload)
    return ApiCaller(BASE_API_URL,`/like`, "post", payload)
  }
  },
  modules: {
  }
})
